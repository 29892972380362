<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Advertising from '@/services/Advertising'
import modalAddSponsor from "@/components/modals/advertising/modalAddSponsor";

/**
 * Cards component
 */
export default {
    components: { Layout, PageHeader, modalAddSponsor },
    page: {
        title: "Sponsor Wall",
        meta: [
        {
            name: "description",
            content: appConfig.description,
        },
        ],
    },
    data() {
        return {
            title: "Sponsors",
            items: [
                {
                    text: "Advertising",
                },
                {
                    text: "Sponsors",
                    active: true,
                },
            ],
            sponsors: null,
            error: null
        };
    },
    middleware: "authentication",

    async created(){
        this.getSponsors()
    },

    methods: {
        getSponsors(){
            Advertising.getSponsors()
            .then(response => {
                this.sponsors =  response.data.data;
            })
            .catch(error => {
                this.error = error.response.data.error ? error.response.data.error : "";
            })
        },

        modalAddNewSponsor() {
            this.$bvModal.show("add_new_sponsor");
        },

    },

};
</script>



<template>
  <Layout>
    <PageHeader :title="title" :items="items" />


    <div class="row mb-3">
        <div class="col-md-6">
        <div class="mb-3">
          <a  href="javascript:void(0);" class="btn btn-success waves-effect waves-light" @click="modalAddNewSponsor">
            <i class="mdi mdi-plus me-2"></i> 
            Add New
        </a>
        </div>
    </div>
    </div>

    <div class="row">
      <div class="col-lg-6 col-xl-3" v-for="sponsor in sponsors" :key="sponsor.id">
        <b-card no-body>
          <b-card-body>
            <b-card-title>
                <h4 class="card-title"><strong>{{sponsor.name}}</strong></h4>
                <h6 class="card-subtitle text-muted"><h6 class="card-subtitle text-muted">
                    Status: <span class="badge  font-size-12"  :class="[sponsor.active ? 'bg-soft-success' : 'bg-soft-danger']">
                        {{sponsor.active==1 ? 'Active' : 'Not Active' }}
                    </span> 
                </h6></h6>
            </b-card-title>

            <div class="example example-cover">
                <img class="img-fluid"  :src="sponsor.logo"   />
            </div>
            
          </b-card-body>
          
          <b-card-body>
                <router-link class="btn btn-primary" :to="{ path: `/advertising/sponsor/${sponsor.id}` }">
                    View Details
                </router-link>
          </b-card-body>
        </b-card>
      </div>
 
    </div>
    <!-- end row -->


     <!-- MODALS --> 
    <modalAddSponsor  @refreshScreen="getSponsors">
    </modalAddSponsor>

  </Layout>
</template>

<style>
.example {
  margin: 1em 0;
  text-align: center;
}

.example img {
  width: 30vw;
  height: 15vw;
}

.example-cover img {
  object-fit: contain;
}

.example-contain img {
  object-fit: contain;
}

</style>


