<script>
    import Advertising from '@/services/Advertising'
    import Swal from "sweetalert2";
    //import Aws from '@/services/Aws'

    export default {
        props: {
        },
        data() {
            return {
                showModal: false,
                tryingToEdit: false,
                tryingToSubmit: false,
                sponsor: {
                    name: null,
                    logo: null,
                    url: null,
                    description: null,
                    attributes: {
                        
                    },
                    file: null
                },
            };
        },
        methods: {
            refreshSponsorData() { 
                this.$emit('refreshScreen') //event from parent
            },
            addSponsor(){
                //submit
                this.tryingToSubmit = true;
                let formData = new FormData();
                formData.append('name', this.sponsor.name);
                formData.append('file', this.sponsor.file);
                formData.append('url', this.sponsor.url);
                formData.append('description', this.sponsor.description);

                Advertising.addSponsor(formData)
                .then((response) => {
                    this.tryingToSubmit = false;
                    const res = response.data.data ? response.data.data : false;
                    const error = response.data.error ? response.data.error : '';
                    if(res){
                        Swal.fire("OK!", "Sponsor Created !", "success")
                        this.refreshSponsorData();
                        this.closeModal();
                    }else{
                        Swal.fire("Fail!", error, "warning")
                    }
                })
                .catch(error => {
                    this.tryingToSubmit = false;
                    const resData = error.response.data.error ? error.response.data.error : 'Failed';
                    const errorMsg = Array.isArray(resData) ? resData[0] : resData;
                    Swal.fire("Fail!", errorMsg, "warning")
                })
            },
            closeModal() {
                this.showModal = false;
            },
            successmsg() {
                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: "Add Success",
                    showConfirmButton: false,
                    timer: 2000,
                });
            },

            failedmsg(msg) {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: msg,
                    showConfirmButton: false,
                    timer: 50000,
                });
            },

            selectFile(event){
                //const fileName = event.target.files[0].name
                const file = event.target.files[0]
                this.sponsor.file = file
                this.sponsor.logo = URL.createObjectURL(file)
                // const aws_folder = process.env.VUE_APP_AWS_S3_IMG_FOLDER + 'sponsor/'
                // if(file) {
                //     Aws.singleUpload(process.env.VUE_APP_AWS_S3_BUCKET, aws_folder, fileName, file)
                //     .then((response) => {
                //         this.sponsor.logo = response.fullPath
                //     })
                // }
            },
        }
    };
</script>



<template>
    <b-modal
        id="add_new_sponsor"
        size="md"
        v-model="showModal"
        title="Add New Sponsor"
        title-class="text-black font-18"
        body-class="p-3"
        hide-footer
    >
    <form @submit.prevent="addSponsor">
        <div class="row">
          <div class="col-12">
            <div class="mb-3">
                <label class="control-label form-label">Name</label>
                <b-form-input  v-model="sponsor.name"></b-form-input>
            </div>

            <div class="mb-3">
                <label class="control-label form-label">Logo</label>
            </div>
            <div class="mb-3">
                <img v-if="sponsor.logo" :src="sponsor.logo"  width="200"   class="rounded me-2" style="max-height: 150px;" />
                <img v-else  width="200" src="@/assets/images/image_not_found.png"  class="rounded me-2" style="max-height: 150px;" />
                <b-form-file @change="selectFile"  type="file" class="mt-3" plain accept="image/jpeg, image/png, image/gif" ></b-form-file>
            </div>

            <div class="mb-3">
                <label class="control-label form-label">Sponsor Destination URL</label>
                <b-form-input  v-model="sponsor.url"></b-form-input>
            </div>
            <div class="mb-3">
                <label class="control-label form-label label-for">Description</label>
                <b-form-textarea  v-model="sponsor.description"   rows="5" ></b-form-textarea>
            </div>
          </div>
        </div>
        <div class="text-end pt-5 mt-3">
            <b-button variant="light" @click="closeModal" >Close</b-button>
            <b-button type="submit" variant="primary" class="ms-3" @click="addSponsor" :disabled="tryingToSubmit">
                Submit
            </b-button>
        </div>
      </form>
    </b-modal>
 
</template>